import { default as indexaWLI227u1sMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/index.vue?macro=true";
import { default as privacy_45policyeoJ0DOPHQdMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/privacy-policy.vue?macro=true";
import { default as registration_45cardbRrtPPOVPQMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/registration-card.vue?macro=true";
import { default as request_45partnerRb8WrXMq6xMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/request-partner.vue?macro=true";
import { default as termsLXkVfurZW6Meta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/terms.vue?macro=true";
import { default as thank_45youoxjG7TkTjnMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/thank-you.vue?macro=true";
import { default as user_45verificationCWECN8oIakMeta } from "/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/user-verification.vue?macro=true";
export default [
  {
    name: indexaWLI227u1sMeta?.name ?? "index",
    path: indexaWLI227u1sMeta?.path ?? "/",
    meta: indexaWLI227u1sMeta || {},
    alias: indexaWLI227u1sMeta?.alias || [],
    redirect: indexaWLI227u1sMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyeoJ0DOPHQdMeta?.name ?? "privacy-policy",
    path: privacy_45policyeoJ0DOPHQdMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyeoJ0DOPHQdMeta || {},
    alias: privacy_45policyeoJ0DOPHQdMeta?.alias || [],
    redirect: privacy_45policyeoJ0DOPHQdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registration_45cardbRrtPPOVPQMeta?.name ?? "registration-card",
    path: registration_45cardbRrtPPOVPQMeta?.path ?? "/registration-card",
    meta: registration_45cardbRrtPPOVPQMeta || {},
    alias: registration_45cardbRrtPPOVPQMeta?.alias || [],
    redirect: registration_45cardbRrtPPOVPQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/registration-card.vue").then(m => m.default || m)
  },
  {
    name: request_45partnerRb8WrXMq6xMeta?.name ?? "request-partner",
    path: request_45partnerRb8WrXMq6xMeta?.path ?? "/request-partner",
    meta: request_45partnerRb8WrXMq6xMeta || {},
    alias: request_45partnerRb8WrXMq6xMeta?.alias || [],
    redirect: request_45partnerRb8WrXMq6xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/request-partner.vue").then(m => m.default || m)
  },
  {
    name: termsLXkVfurZW6Meta?.name ?? "terms",
    path: termsLXkVfurZW6Meta?.path ?? "/terms",
    meta: termsLXkVfurZW6Meta || {},
    alias: termsLXkVfurZW6Meta?.alias || [],
    redirect: termsLXkVfurZW6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: thank_45youoxjG7TkTjnMeta?.name ?? "thank-you",
    path: thank_45youoxjG7TkTjnMeta?.path ?? "/thank-you",
    meta: thank_45youoxjG7TkTjnMeta || {},
    alias: thank_45youoxjG7TkTjnMeta?.alias || [],
    redirect: thank_45youoxjG7TkTjnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: user_45verificationCWECN8oIakMeta?.name ?? "user-verification",
    path: user_45verificationCWECN8oIakMeta?.path ?? "/user-verification",
    meta: user_45verificationCWECN8oIakMeta || {},
    alias: user_45verificationCWECN8oIakMeta?.alias || [],
    redirect: user_45verificationCWECN8oIakMeta?.redirect || undefined,
    component: () => import("/home/runner/work/happybox-prenatal-v2/happybox-prenatal-v2/pages/user-verification.vue").then(m => m.default || m)
  }
]